<template>
    <div class="tgcss-h-full tgcss-relative tgcss-grow">
      <iframe class="!tgcss-h-full !tgcss-w-full" id="tv-iframe" src="https://panel.tgju.gold/"></iframe>
    </div>
  </template>
  <script>
  export default {
    data() {
          return {
          }
      },
  };
  </script>